import React, {useState , useContext, useEffect} from 'react';
import Inicio from './Inicio/Inicio';
//import Perfil from './Perfil/Perfil';
import Seguro from './Seguro/Seguro';
import Diagnostico from './Diagnostico/Diagnostico';
import Centros from "./Centros/Centros";
import Resultado from './Resultado/Resultado';
import Arrow from './img/Arrow.svg'
import ArrowUp from './img/ArrowUp.svg'
import { PasoContext } from '../../../context/PasoContext';
import Text from '../Text/Text';



const Carrousel = () =>{
   
    const {paso} = useContext(PasoContext);
    const [pagina,setPagina]= useState(paso.id)
    //console.log(paso)

    const [disabled,setDisabled] = React.useState(false);
    const siguientePagina=()=>{
        if(pagina<4 && pagina>1){
            setPagina(pagina+1)
            paso.id++;
        }
    }

    const anteriorPagina = () =>{
        //cambiar a 2
        if(pagina>2){
            setPagina(pagina-1)
            paso.id--;
        }
    }

    useEffect(()=>{
        setPagina(paso.id)
        if(paso.id === 3){
            setDisabled(true)
        }else{
            setDisabled(false)
        }
    })

    const inputCambio = (e) =>{
        e.preventDefault();
        if(e.target.value == 'Aún no lo sé'){
            setDisabled(true);
        }else{
            setDisabled(false);
        }
    }

    
    return(
        <div className="card wrapper">
            {
                paso.id==0? <Text />:
                paso.id==1? <Inicio /> :
                //paso.id==2? <Perfil /> : se quito esta pantalla por requerimiento
                paso.id==2? <Seguro />:
                paso.id==3? <Diagnostico inputCambio={inputCambio} /> :
                paso.id==4? <Centros /> :
                paso.id==5? <Resultado /> : null
            }
        </div>
    )
}
export default Carrousel