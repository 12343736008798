import * as React from 'react';

const Footer = () => {
    return(
        <div className="footer">
            <div className="footer-logo">
            <p>Este ejercicio digital es una simulación con base en los datos proporcionados por el usuario. No respresenta ningún compromiso final con alguna aseguradora o sus servicios.</p> 
            </div>
            <div className="footer-nav">
  
                    <ul className="footer-nav--list">
                        <li className="footer-nav--list_item"><a className="link" href="#">Términos y Condiciones</a></li>
                        <li className="footer-nav--list_item"><a className="link" href="#">Aviso de Privacidad</a></li>
                    </ul>
            </div>
        </div>
    )
}

export default Footer;