import * as React from 'react';

import { PasoContext } from '../../context/PasoContext'

import CarrouselCard from './Card/CarraouseCards';



const Content = () => {
    const {paso} = React.useContext(PasoContext);

    


    return(
        <div className="content">
             
            <CarrouselCard />
            
        </div>
    )

}

export default Content;