import React, { useState } from 'react'
import { PasoContext } from '../../../context/PasoContext';
import imgPresentar from '../../../../src/images/cami-inicio.png';


const Text = () => {
    const { paso, setPaso } = React.useContext(PasoContext);
    

    function handleSiguiente(e) {
        e.preventDefault();

        setPaso({ ...paso, id: 1 });
    }
    return(
        <div className="row reverse-md">
            <div className="text col-lg-7 col-md-11">
                <h3>Simulador de inversión</h3>
                <p>
                Obtén en 4 sencillos pasos una proyección de 
                tu Seguro de Gastos Médicos 
                Mayores para evaluar 
                tu tratamiento de cáncer de mama
                </p>
                <input type="button"
                    className="btn icon marginTop fa fas"
                    value=""
                    onClick={handleSiguiente}
                />
            </div>
            <div className="col-lg-5 col-md-11">
                <img className="img-md-80" src={imgPresentar} alt="" />
            </div>
        </div>
    )

}

export default Text;