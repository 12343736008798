import * as React from 'react';

import iconEdit from '../../../../images/edit-con.svg'

import Simulacion from '../../../../api/simulacionAPI';
import FormularioContacto from './Formulario/FormularioContacto';
import { PasoContext } from '../../../../context/PasoContext';
import backgroundResultado from '../../../../images/background-resumen.png';


const Resultado = (resultadoDef) => {

    const [contacto, setContacto] = React.useState(false);
    const [radio, setRadio] = React.useState(null);
    const [isEditablePoliza, setisEditablePoliza] = React.useState(false)
    const [isEditableDiagnostico, setisEditableDiagnostico] = React.useState(false)
    const [listaI, setListaI] = React.useState([]);
   
    const [cotizacion, setCotizacion] = React.useState([]);
    const [errors, setErrors] = React.useState('');

    const { paso, setPaso } = React.useContext(PasoContext);
    
     

    const [listaMama, setMama] = React.useState([]);
    const [listaHormonal, setHormonal] = React.useState([]);
    const [listaHer, setHer] = React.useState([]);
    const [listaBRCA, setBRCA] = React.useState([]);
    const polizaEditable = React.createRef()
    const diagnosticoEditable = React.createRef()
    const hospitalRef = React.useRef();
    const mamaRef = React.useRef();
    const hormonalRef = React.useRef();
    const herRef = React.useRef();
    const brcaRef = React.useRef();


    //view value se creo para reemplazar paso, como no se si se tiene que impactar el cambio, mejor dejar una simulacion.
    const [viewValues, setViewValues] = React.useState({ ...paso })
    const [diagnostico, setDiagnostico] = React.useState({mama: paso.mama, 
        hormonal: paso.hormonal, 
        her: paso.her, 
        brca: paso.brca});
        
    if (paso.simulation_number === '') {
        setPaso({ ...paso, simulation_number: Math.floor(Math.random() * 100) + 112233 })
    }
    
    React.useEffect(() => {
               
                 //-- Carga de datos del API Rest Simulacion
                if(!listaMama.length){
                    Simulacion.getCancerMama().then(data => setMama(data))
                }
                if(!listaHormonal.length){
                    Simulacion.getReceptoresHormonal().then(data => setHormonal(data))
                }
                if(!listaHer.length){
                    Simulacion.getHer2().then(data => setHer(data))
                }
                if(!listaBRCA.length){
                    Simulacion.getBRCA().then(data => setBRCA(data))
                }
            
             

        //si en el primer caracter no hay un $
        if (viewValues.suma_asegurada[0] !== '$') {
            setViewValues({ ...viewValues, suma_asegurada: '$' + viewValues.suma_asegurada })
        }
        if (viewValues.deducible[0] !== '$') {
            setViewValues({ ...viewValues, deducible: '$' + viewValues.deducible })
        }
        //si en el ultimo caracter no tiene un %
        if (viewValues.coaseguro[viewValues.coaseguro.length - 1] !== '%') {
            setViewValues({ ...viewValues, coaseguro: viewValues.coaseguro + "%" })
        }
        Simulacion.getInstituciones().then(data => setListaI(data))
        Simulacion.postCotizacion(paso).then(data => {
            setPaso({ ...paso, id_institucion: hospitalRef.current.value, items: data.items })
            setCotizacion(data);
            //console.log("paso1",paso)
            if (paso.items?.length === 0) {
                setPaso({ ...paso, items: data["items"] })
            }
        })
            .catch(err => {
                setCotizacion([]);
                setErrors('No se encuentra este perfil.')
            });
            console.log(paso.centro,"paso")
            if(paso.centro != 'Centro Oncológico' ){
                setRadio(false)
            }else{
                setRadio(true)
            }
    }, [viewValues], [])


    React.useEffect(() => {
       
        //al hacer click activo o desactivo que se edite o no
        
        if (isEditablePoliza) {
            polizaEditable.current.children[0].children[1].readOnly = false
            polizaEditable.current.children[1].children[1].readOnly = false
            polizaEditable.current.children[2].children[1].readOnly = false
        } else {
            polizaEditable.current.children[0].children[1].readOnly = true
            polizaEditable.current.children[1].children[1].readOnly = true
            polizaEditable.current.children[2].children[1].readOnly = true
        }
        if (isEditableDiagnostico) {
            diagnosticoEditable.current.children[0].children[1].readOnly = false
            diagnosticoEditable.current.children[1].children[1].readOnly = false
            diagnosticoEditable.current.children[2].children[1].readOnly = false
            diagnosticoEditable.current.children[3].children[1].readOnly = false
        } else {
            diagnosticoEditable.current.children[0].children[1].readOnly = true
            diagnosticoEditable.current.children[1].children[1].readOnly = true
            diagnosticoEditable.current.children[2].children[1].readOnly = true
            diagnosticoEditable.current.children[3].children[1].readOnly = true
        }

      polizaEditable.current.children[1].children[1].value = "$"+polizaEditable.current.children[1].children[1].value.replace(/\D/g, "").replace(/([0-9])([0-9]{3})$/, '$1,$2').replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ",")
      polizaEditable.current.children[0].children[1].value = "$"+polizaEditable.current.children[0].children[1].value.replace(/\D/g, "").replace(/([0-9])([0-9]{3})$/, '$1,$2').replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ",")
    }, [isEditablePoliza, isEditableDiagnostico, polizaEditable, diagnosticoEditable], [])


    function handleGenerar(e) {
        e.preventDefault();
        setContacto(true);
    }

    function handleFInalizar(e) {
        e.preventDefault();

        // Iniciliza todos los valores de Paso
        // Para comenzar todo desde cero
        setPaso({
            id: 0,
            genre: '',
            edad: '',
            aseguradora: '',
            suma_asegurada: '',
            coaseguro: '',
            deducible: '',
            vigencia: '',
            mama: '',
            hormonal: '',
            her: '',
            brca: '',
            centro: '',

            id_receptor_hormonal: 1,
            id_status_her: 1,
            id_status_brca: 1,
            id_etapa_cdm: 1,
            id_aseguradora: 1,
            id_institucion: 1,
            aprobed_forms: 0,
            simulation_number: '',
            items: []
        });
    }

    function handleDescargarSimulacion() {
        Simulacion.postDescargarSimulacion(paso.simulation_number, paso);
        console.log(paso.simulation_number,paso)
    }
    
    function handleChange(e) {
        e.preventDefault();
        console.log(e.target.value)
            switch(e.target.name){
                case 'mama': {
                    const mamaID = listaMama.find(data => data.nombre == e.target.value)
                    setViewValues({...viewValues, mama: e.target.value});
                    setPaso({...paso,mama:e.target.value, id_etapa_cdm: mamaID.id})
                    //console.log(viewValues,"value")
                    //console.log(paso,"cotizacion paso")
                    break
                }
                case 'hormonal': {
                    const hormonalID = listaHormonal.find(data => data.nombre == e.target.value)
                    setViewValues({...viewValues, hormonal: e.target.value});
                    setPaso({...paso,hormonal:e.target.value, id_receptor_hormonal: hormonalID.id})
                    //console.log(viewValues,"value")
                    //console.log(paso,"cotizacion paso")
                    break
                }
                case 'her': {
                    const herID = listaHer.find(data => data.nombre === e.target.value)
                    setViewValues({...viewValues, her: e.target.value});
                    setPaso({...paso,her:e.target.value, id_status_her: herID.id})
                    //console.log(viewValues,"value")
                    break
                }
                case 'brca': {
                    const brcaID = listaBRCA.find(data => data.nombre === e.target.value)
                    console.log(brcaID)
                    setViewValues({...viewValues, brca: e.target.value});
                    setPaso({...paso,brca:e.target.value, id_status_brca: brcaID.id})
                    //console.log(viewValues,"value")
                    break
                }
                
                
            }
            
        //viewError()
    }

    function handleChangeInstituto() {
        setCotizacion("")
        Simulacion.postCotizacion({ ...paso, id_institucion: hospitalRef.current.value }).then(data => {
            setPaso({ ...paso, id_institucion: hospitalRef.current.value, items: data.items })
            setCotizacion(data);
        });
    }

    function formatNumberMX(nro) {
        const num= new Intl.NumberFormat("ES-MX", {
            style: 'currency',
            currency: 'MXN'
        }).format(nro);

        //se quito la coma por pedido del cliente
        return num.toString().slice(0, -3);
    }

    const formatoMexico = (number) => {
        number = parseInt(number)
        const exp = /(\d)(?=(\d{3})+(?!\d))/g;
        const rep = '$1,';
        return number.toString().replace(exp,rep);
    }


    function cambiarTipoAtencion(){
        console.log(radio)
        if(radio){
            setRadio(false)
            setPaso({ ...paso, centro: 'Enfoque multidisciplinario' })
            console.log(paso)
            console.log(radio)
            //setRadio(false)
            return
        }else{
            setRadio(true)
            setPaso({ ...paso, centro: 'Centro Oncológico' })
            console.log(paso)
            console.log(radio)
            //setRadio(true)
            return
        }
        
    }

    function editInput(e){
        setViewValues({ ...viewValues, suma_asegurada: e.target.value })
        console.log(viewValues,"viewvalues")
    }

    if (errors !== '' && cotizacion.length === 0)
        return (
            <div key="resultado" className="resultado">
                <h1 className="resultado-title">Simulación Mo. {paso.simulation_number}</h1>

                <div className="resultado-datos">
                    <h1 className="resultado-datos_title">Resumen de datos</h1>

                    <div className="flex">
                        <div className="flex-item1">
                            <div className={`card1 ${isEditablePoliza ? "editable" : ""}`}>
                                <div className="d-flex">
                                    <h3>Resumen de póliza </h3>
                                    <button className="btn-edit" onClick={() => setisEditablePoliza(!isEditablePoliza)}>
                                        <img src={iconEdit} alt="edit" />
                                    </button>
                                </div>
                                <div ref={polizaEditable} className="contenido-editable">
                                    <div className="item">
                                        <h4>Suma asegurada:</h4>
                                        <input type="text" onChange={(e) => setViewValues({ ...viewValues, suma_asegurada: e.target.value })} value={viewValues.suma_asegurada} />
                                    </div>
                                    <div className="item">
                                        <h4>Deducible:</h4>
                                        <input type="text" onChange={(e) => setViewValues({ ...viewValues, deducible: e.target.value })} value={viewValues.deducible} />
                                    </div>
                                    <div className="item">
                                        <h4>Coaseguro:</h4>
                                        <input type="text" onChange={(e) => setViewValues({ ...viewValues, coaseguro: e.target.value })} value={viewValues.coaseguro} />
                                    </div>
                                </div>
                            </div>

                            <p className="flex-item1--p">
                                Tengo dudas sobre mi póliza <br />
                                <a href="#" className="link">Quiero contactar a mi seguro</a>
                            </p>
                        </div>

                        <div className="flex-item2">
                            <div className={`card2 ${isEditableDiagnostico ? "editable" : ""}`}>
                                <div className="flex-row">
                                    <h3>Resumen del Diagnóstico </h3>
                                    <button className="btn-edit" onClick={() => setisEditableDiagnostico(!isEditableDiagnostico)}>
                                        <img src={iconEdit} alt="edit" />
                                    </button>
                                </div>
                                <div ref={diagnosticoEditable} className="contenido-editable">
                                    <div className="grid-container">
                                        <h4>Etapa del cáncer de mama:</h4>
                                       
                                    </div>
                                    <div className="grid-container">
                                        <h4>Receptor Hormonal:</h4>
                                        <input type="text" onChange={(e) => setViewValues({ ...viewValues, hormonal: e.target.value })} value={viewValues.hormonal} />
                                    </div>
                                    <div className="grid-container">
                                        <h4>Estatus de HER2:</h4>
                                        <input type="text" onChange={(e) => setViewValues({ ...viewValues, her: e.target.value })} value={viewValues.her} />
                                    </div>
                                    <div className="grid-container">
                                        <h4>Estatus de BRCA:</h4>
                                        <input type="text" onChange={(e) => setViewValues({ ...viewValues, brca: e.target.value })} value={viewValues.brca} />
                                    </div>
                                </div>
                            </div>
                        </div> {/*--- FIN FLEX-CONTAINER--- */}
                    </div>

                </div> {/*--- FIN DATOS--- */}
                <div className="resultado-errors">
                    {errors}
                </div>
            </div>)



    return (
        <div className="resultado" style={{ backgroundImage: `url(${backgroundResultado})` }}>
            <h1 className="resultado-title">Simulación No. {paso.simulation_number}</h1>

            <div className="resultado-datos">
                <div className="tipo-atencion">
                    <div>
                        <h3>Tipo de atención que quieres</h3>
                        <div className="oncologo-o-multidisi">
                            <p className={radio ? "left" : ""}>Centro Oncológico</p>
                            <div onClick={() => cambiarTipoAtencion()} className={`interrputor ${radio ? "left" : "right"}`}>
                                <span className="radio" />
                            </div>
                            <p className={radio ? "" : "right"}>Enfoque multidisciplinario</p>
                        </div>
                    </div>
                </div>

                <h1 className="resultado-datos_title">Resumen de datos</h1>

                <div className="flex">
                    <div className="flex-item1">
                        <div className={`card1 ${isEditablePoliza ? "editable" : ""}`}>
                            <div className="d-flex">
                                <h3>Resumen de póliza </h3>
                                <button className="btn-edit" onClick={() => setisEditablePoliza(!isEditablePoliza)}>
                                    <img src={iconEdit} alt="edit" />
                                </button>
                            </div>
                            <div ref={polizaEditable} className="contenido-editable">
                                <div className="item">
                                    <h4>Suma asegurada:</h4>
                                    <input type="text" onChange={(e) => editInput(e)} value={viewValues.suma_asegurada} />
                                </div>
                                <div className="item">
                                    <h4>Deducible:</h4>
                                    <input type="text" onChange={(e) => setViewValues({ ...viewValues, deducible: e.target.value })} value={viewValues.deducible} />
                                </div>
                                <div className="item">
                                    <h4>Coaseguro:</h4>
                                    <input type="text" onChange={(e) => setViewValues({ ...viewValues, coaseguro: e.target.value })} value={viewValues.coaseguro} />
                                </div>
                            </div>
                        </div>

                        <p className="flex-item1--p">
                            Tengo dudas sobre mi póliza <br />
                            <a href="#" className="link">Quiero contactar a mi seguro</a>
                        </p>
                    </div>

                    <div className="flex-item2">
                        <div className={`card2 ${isEditableDiagnostico ? "editable" : ""}`}>
                            <div className="flex-row">
                                <h3>Resumen del Diagnóstico </h3>
                                <button className="btn-edit" onClick={() => setisEditableDiagnostico(!isEditableDiagnostico)}>
                                    <img src={iconEdit} alt="edit" />
                                </button>
                            </div>
                            <div ref={diagnosticoEditable} className="contenido-editable">
                                <div className="grid-container">
                                    <h4>Etapa del cáncer de mama:</h4>
                                    {
                                        !isEditableDiagnostico 
                                        ?
                                        <input type="text" onChange={(e) => setViewValues({ ...viewValues, mama: e.target.value })} value={viewValues.mama} />
                                        :
                                        <select name="mama" id="mama" ref={mamaRef} onChange={handleChange}  >
                                            <option selected disabled  value={viewValues.mama}>{viewValues.mama}</option>
                                        {
                                            listaMama.map((data)=> {
                                                if(data.nombre != 'Aún no lo sé'){
                                                    return <option key={data.id} value={data.nombre}>{data.nombre}</option>
                                                }
                                            })
                                        }
                                        
                                        </select>
                                    }
                                </div>
                                <div className="grid-container">
                                    <h4>Receptor Hormonal:</h4>
                                    {
                                        !isEditableDiagnostico
                                        ?
                                        <input type="text" onChange={(e) => setViewValues({ ...viewValues, hormonal: e.target.value })} value={viewValues.hormonal} />
                                        :
                                        <select name="hormonal" id="hormonal" onChange={handleChange} ref={hormonalRef} >
                                        <option selected disabled  value={viewValues.hormonal}>{viewValues.hormonal}</option>
                                        {
                                            listaHormonal.map((data)=> {
                                                if(data.nombre != 'Aún no lo sé'){
                                                    return <option key={data.id} value={data.nombre}>{data.nombre}</option>
                                                }
                                            })
                                        }
                                        </select>
                                    }
                                    
                                </div>
                                <div className="grid-container">
                                    <h4>Estatus de HER2:</h4>
                                    {
                                        !isEditableDiagnostico
                                        ?
                                        <input type="text" onChange={(e) => setViewValues({ ...viewValues, her: e.target.value })} value={viewValues.her} />
                                        :
                                        <select name="her" id="her" onChange={handleChange} ref={herRef} >
                                         <option selected disabled  value={viewValues.her}>{viewValues.her}</option>
                                        {
                                            listaHer.map((data)=> {
                                                if(data.nombre != 'Aún no lo sé'){
                                                    return <option key={data.id} value={data.nombre}>{data.nombre}</option>
                                                }
                                            })
                                        }
                                        </select>
                                    }
                                  
                                </div>
                                <div className="grid-container">
                                    <h4>Estatus de BRCA:</h4>
                                    {
                                        isEditableDiagnostico
                                        ?
                                        <select name="brca" id="brca" onChange={handleChange} ref={brcaRef} >
                                        <option selected disabled  value={viewValues.brca}>{viewValues.brca}</option>
                                        {
                                            listaBRCA.map((data)=> {
                                                if(data.nombre != 'Aún no lo sé'){
                                                    return <option key={data.id} value={data.nombre}>{data.nombre}</option>
                                                }
                                            })
                                        }
                                        </select>
                                        :
                                        <input type="text" onChange={(e) => setViewValues({ ...viewValues, brca: e.target.value })} value={viewValues.brca} />
                                    }
                                </div>
                            </div>
                        </div>

                    </div> {/*--- FIN FLEX-CONTAINER--- */}

                </div>

            </div> {/*--- FIN DATOS--- */}

            <div className="resultado-costo">
                <h1 className="resultado-costo--title">Costo estimado del tratamiento anual</h1>
                <form className="costo-form">
                    <div className="form-control">
                        <select name="instituciones" id="instituciones" onChange={() => handleChangeInstituto()}
                            ref={hospitalRef}
                            option={paso.items}>
                            <option value="Select" disabled>Seleccionar</option>
                            {
                                listaI?.map((item) => {
                                    return <option key={item.id} value={item.id}>{item.nombre}</option>
                                })
                            }
                        </select>
                    </div>
                    {
                        (cotizacion?.length === 0) ? <div className="cargando"> Cargando Monto ....</div> :
                            <p className="form-text"> {formatNumberMX(cotizacion.costo_total)}</p>
                    }
                </form>
                {
                    (cotizacion?.length === 0) ? <div className="cargando"> Cargando Detalle ....</div>
                        :
                        <div>
                            <div className="resultado-costo_detalle">
                                <h2 className="resultado-costo_detalle--title">Detalles*:</h2>
                                {
                                    (paso.items?.length !== 0) ?
                                        paso.items?.map(data => {
                                            return <div className="flex-column">
                                                <h4 className="flex-container">
                                                    <div> {data.nombre}: &nbsp;  &nbsp;  &nbsp;  </div>
                                                    <div>${formatoMexico((data.precio_preferencial * data.cantidad))}</div>
                                                </h4>
                                              
                                            </div>
                                        })
                                        :
                                        <div>Cargando ....</div>
                                }
                                <h4 className="flex-container">
                                    <div> Total por 1 año</div>
                                    <div>{formatNumberMX(cotizacion.costo_total)}</div>
                                    
                                </h4>
                            </div>
                            <div>
                                    {   
                                    parseInt(cotizacion.costo_total) > parseInt(viewValues.suma_asegurada.replace(/[$.,]/g,''))  ? <p className="aviso">El costo del tratamiento anual está por encima de la suma asegurada de tu póliza</p>: <p></p>
                                    }
                            </div>
                        </div>
                        
                }

            </div>
            {/* FIN RESULTADOS */}


            <div className="resultado-costo_buttons">
                <button className="btn btn-generar marginRight" onClick={handleGenerar}>Generar Preaprobación</button>
                <button className="btn btn-descargar marginRight" onClick={() => handleDescargarSimulacion()}>Descargar Simulación</button>
                <button className="btn btn-finalizar marginRight" onClick={handleFInalizar}>Finalizar Simulación</button>
            </div>

            <div className="resultado-legal">
                <p>Legal que menciona que el resultado puede ser variable y que depende de la decisón de cada médico.</p>
            </div>
            {(contacto) ? <FormularioContacto nroSimulacion={paso.simulation_number} costo_total={cotizacion.costo_total} />
                : <div></div>}
        </div>
    )

}


export default Resultado;